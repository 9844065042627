// utils/api.js
import axios from 'axios';
import { notify } from './helper';
// import { cookies } from 'next/headers';

// export const API_BASE_URL = 'https://tnmmediaacademy-server.onrender.com/api/v1';
// export const API_BASE_URL = 'https://server.tnmmediaacademy.edu.ng/api/v3';
// export const API_BASE_URL = 'http://localhost:7010/api/v3';
// export const API_BASE_URL = 'http://localhost:7010/api/v4';
export const API_BASE_URL = process.env.NEXT_PUBLIC_SERVER_BASE_URL;

const api = axios.create({
    baseURL: API_BASE_URL,
    // You can add headers or other configurations here
});


export const test = async () => {
    try {
        // const response = await api.get('/test');
        const response = await api({
            method: 'get',
            url: '/test'
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const getProgrammes = async () => {
    console.log('HELOPPPPPP LOL')
    try {
        const response = await api({
            method: 'get',
            url: '/aspirant/programmes'
        });

        if (!response) throw new Error('Could not fetch programmes')

        return response.data;
    } catch (error) {
        console.log(error)
        throw error
    }
};

export const getProfile = async (token) => {
    try {
        const response = await api({
            method: 'get',
            url: `/aspirant/profile`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log(response.data)

        return response.data;
    } catch (error) {
        console.log(error);
        throw error
    }
}

// PROF ASPIRANT

export const createAccount = async (data) => {
    try {
        const response = await api({
            method: 'post',
            url: '/aspirant/professional',
            data
        });

        if (!response) throw new Error('Could not create account')

        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const verifyEmailAndGetFormFee = async (token) => {
    try {
        const response = await api({
            method: 'get',
            url: `/aspirant/professional/email/verify?token=${token}`
        });

        return response.data;
    } catch (error) {
        throw error
    }
}

export const payFormFee = async (data) => {
    try {
        const response = await api({
            method: 'post',
            url: `/aspirant/professional/${data.stream}/form/pay?split=false`,
            data
        });

        return response.data;
    } catch (error) {
        throw error;
    }
}

export const verifyFormFeePayment = async (fee_id, stream, reference) => {
    try {
        const response = await api({
            method: 'post',
            url: `/aspirant/professional/${stream}/form/verify?reference=${reference}`,
            data: { fee_id }
        });

        return response.data;
    } catch (error) {
        console.log(error)
        throw error;
    }
}

export const profLogin = async (data) => {
    try {
        const response = await api({
            method: 'post',
            url: '/aspirant/professional/login',
            data
        });

        // if (!response) throw new Error('Failed to login')

        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const profApplication = async (token, data, stream) => {
    try {
        const response = await api({
            method: 'post',
            url: `/aspirant/professional/${stream}/apply`,
            data,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        if (!response) throw new Error('Application failed')

        return response.data;
    } catch (error) {
        console.log(error.response.data);
        // if(error.response.data) throw error.response.data.message
        // throw error;
        if (error.response?.data) {
            notify('error', error.response.data.message)
        } else {
            notify('error', error.message);
        }
    }
}

export const refreshApplicationStatus = async (token, data, stream) => {
    try {
        const response = await api({
            method: 'post',
            url: `/aspirant/professional/${stream}/apply`,
            data,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        if (!response) throw new Error('Application failed')

        // const dt = JSON.parse(cookies().get('prof_aspirant').value).data
        // cookies().set('prof_aspirant', JSON.stringify({
        //     token,
        //     data: {
        //         ...dt,
        //         status: response.data.data.status
        //     }
        // }))

        return response.data;
    } catch (error) {
        console.log(error.response.data);
        // throw error;
    }
}

export const getIDCardFee = async (token) => {
    try {
        const response = await api({
            method: 'get',
            url: `/aspirant/ID_card`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (!response) throw new Error('Failed to fetch');

        return response.data;
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const getAcceptanceFee = async (token, stream) => {
    try {
        const response = await api({
            method: 'get',
            url: `/aspirant/professional/${stream}/acceptance`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        if (!response) throw new Error('Failed to fetch');

        return response.data;
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const payAcceptanceFee = async (token, stream) => {
    try {
        const response = await api({
            method: 'get',
            url: `/aspirant/professional/${stream}/acceptance/pay?split=false`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        console.log(error)
        throw error;
    }
}

export const verifyAcceptanceFeePayment = async (token, fee_id, stream, reference) => {
    try {
        const response = await api({
            method: 'post',
            url: `/aspirant/professional/${stream}/acceptance/verify?reference=${reference}`,
            data: { fee_id },
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        console.log(error)
        throw error;
    }
}

export const getSchoolFee = async (token, stream, split) => {
    try {
        const response = await api({
            method: 'get',
            url: `/aspirant/professional/${stream}/school_fee?split=${split}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (!response) throw new Error('Failed to fetch');

        return response.data;
    } catch (error) {
        console.log(error.response.data);
        throw error
    }
}

export const payTutionWithIDCardFee = async (token, stream, split) => {
    try {
        const response = await api({
            method: 'get',
            url: `/aspirant/professional/${stream}/school_fee/pay?split=${split}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const verifyTutionFeePayment = async (token, fee_ids, stream, reference) => {
    try {
        const response = await api({
            method: 'post',
            url: `/aspirant/professional/${stream}/school_fee/verify?reference=${reference}`,
            data: { fee_ids },
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        console.log(error)
        throw error;
    }
}

export const payBothFee = async (token, stream, split) => {
    try {
        const response = await api({
            method: 'get',
            url: `/aspirant/professional/${stream}/acceptance_school_fee/pay?split=${split}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        throw error;
    }
}

export const verifyBothFeePayment = async (token, fee_ids, stream, reference) => {
    try {
        const response = await api({
            method: 'post',
            url: `/aspirant/professional/${stream}/acceptance_school_fee/verify?reference=${reference}`,
            data: { fee_ids },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        console.log(error)
        throw error;
    }
}


// NID

export const getPutmeFee = async () => {
    try {
        const response = await api({
            method: 'get',
            url: `/aspirant/putme`
        });

        return response.data;
    } catch (error) {
        throw error
    }
}

export const registerAndPayPutmeFee = async (data) => {
    try {
        const response = await api({
            method: "post",
            url: "/aspirant/register",
            data,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        return response.data
    } catch (error) {
        throw error
    }
}

export const verifyPutmeFeePayment = async (fee_id, reference) => {
    try {
        const response = await api({
            method: 'post',
            url: `/aspirant/register/PUTME/verify?reference=${reference}`,
            data: { fee_id }
        });

        return response.data;
    } catch (error) {
        console.log(error)
        throw error;
    }
}

export const nidLogin = async (data) => {
    try {
        const response = await api({
            method: 'post',
            url: `/aspirant/login`,
            data
        });

        return response.data;
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const uploadAdmissionLetter = async (token, data) => {
    try {
        const response = await api({
            method: 'patch',
            url: `/aspirant/admission`,
            data,
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const getNIDAcceptanceFee = async (token) => {
    try {
        const response = await api({
            method: 'get',
            url: `/aspirant/acceptance`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        if (!response) throw new Error('Failed to fetch');
        console.log(response.data)
        return response.data;
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const payNIDAcceptanceFee = async (token) => {
    try {
        const response = await api({
            method: 'get',
            url: `/aspirant/acceptance/pay?split=false`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        console.log(error)
        throw error;
    }
}

export const verifyNIDAcceptanceFeePayment = async (token, fee_id, reference) => {
    try {
        const response = await api({
            method: 'post',
            url: `/aspirant/acceptance/verify?reference=${reference}`,
            data: { fee_id },
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        console.log(error)
        throw error;
    }
}

export const getNIDSchoolFee = async (token, split) => {
    try {
        const response = await api({
            method: 'get',
            url: `/aspirant/school_fee?split=${split}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (!response) throw new Error('Failed to fetch');

        return response.data;
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const payNIDTutionWithIDCardFee = async (token, split) => {
    try {
        const response = await api({
            method: 'get',
            url: `/aspirant/school_fee/pay?split=${split}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        throw error;
    }
}

export const verifyNIDTutionFeePayment = async (token, fee_ids, reference) => {
    try {
        const response = await api({
            method: 'post',
            url: `/aspirant/school_fee/verify?reference=${reference}`,
            data: { fee_ids },
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        console.log(error)
        throw error;
    }
}

export const payNBothFee = async (token, split) => {
    try {
        const response = await api({
            method: 'get',
            url: `/aspirant/acceptance_school_fee/pay?split=${split}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        throw error;
    }
}

export const verifyNBothFeePayment = async (token, fee_ids, reference) => {
    try {
        const response = await api({
            method: 'post',
            url: `/aspirant/acceptance_school_fee/verify?reference=${reference}`,
            data: { fee_ids },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        console.log(error)
        throw error;
    }
}

// STUDENT

export const studentLogin = async (data) => {
    try {
        const student = await api({
            method: 'post',
            url: `/student/signin`,
            data,
            headers: {
                "Content-Type": "application/json",
            }
        })

        return student.data;
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getMe = async (token) => {
    try {
        const student = await api({
            method: 'get',
            url: `/student`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })

        return student.data;
    } catch (error) {
        console.log(error)
        throw error;
    }
}

export const getCourses = async (token, stream) => {
    try {
        const courses = await api({
            method: 'get',
            url: `/courses/${stream}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })

        return courses.data;
    } catch (error) {
        console.log(error)
        throw error;
    }
}

export const getMyCourses = async (token) => {
    try {
        const courses = await api({
            method: 'get',
            url: `/courses`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })

        return courses.data;
    } catch (error) {
        console.log(error)
        throw error;
    }
}

export const getFees = async (token) => {
    try {
        const courses = await api({
            method: 'get',
            url: `/fees`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })

        return courses.data;
    } catch (error) {
        console.log(error)
        throw error;
    }
}

export const registerCourses = async (token, ids) => {
    try {
        const courses = await api({
            method: 'post',
            url: `/courses/register`,
            data: { ids },
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })

        return courses.data;
    } catch (error) {
        console.log(error)
        throw error;
    }
}

export const dropCourse = async (token, id) => {
    try {
        const response = await api({
            method: 'delete',
            url: `/courses/${id}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
        if (error.response?.data) {
            notify('error', error.response.data.message)
        } else {
            notify('error', error.message);
        }
    }
}

export const payOutstandingFee = async (token) => {
    try {
        const response = await api({
            method: 'get',
            url: `/fees/pay`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        throw error;
    }
}

export const verifyOutstandingFeePayment = async (token, fee_ids, reference, residual) => {
    try {
        const response = await api({
            method: 'post',
            url: `/fees/verify?reference=${reference}&residual=${residual}`,
            data: { fee_ids },
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        console.log(error)
        throw error;
    }
}

export const getAcademicSessions = async () => {
    try {
        const respnse = await api({
            method: 'get',
            url: `/student/sessions`
        })

        return respnse.data;
    } catch (error) {
        console.log(error);
        // throw error;
    }
}

export const getResult = async (token, query) => {
    const { level, semester, session_id } = query;
    try {
        const response = await api({
            method: 'get',
            url: `/student/result?level=${level}&semester=${semester}&session_id=${session_id}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        console.log(error)
        throw error;
    }
}

export const getAdmissionLetter = async (token) => {
    try {
        const response = await api({
            method: 'get',
            url: `/student/admission_letter`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: 'blob',
        });

        console.log(response)

        if (response.status != 200) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        // return response.blob();
        // return response.data

        // Extracting filename from the Content-Disposition header
        const contentDisposition = response.headers['content-disposition'];
        let filename = 'Admission Letter.pdf';
        if (contentDisposition) {
            const matches = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }
        console.log('Content-Disposition:', contentDisposition);
        console.log('Extracted filename:', filename);
        // You now have the blob and the filename
        return { blob: response.data, filename };
    } catch (error) {
        console.log(error)
    }
}

export const generateExamSlip = async (token) => {
    try {
        const response = await api({
            method: 'get',
            url: `/student/exam_slip`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: 'blob',
        });

        if (response.status != 200) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const contentDisposition = response.headers['content-disposition'];
        let filename = 'Exam slip.pdf';
        if (contentDisposition) {
            const matches = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }

        return { blob: response.data, filename };
    } catch (error) {

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const errorData = await error.response.data.text().then(text => {
                try {
                    return JSON.parse(text); // Attempt to parse the error response
                } catch {
                    return { message: 'An unknown error occurred' }; // Fallback error message
                }
            });

            console.error('Error message:', errorData.message);
            throw errorData.message
            // Handle error (e.g., show error message to the user)
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received');
            throw new Error('No response received')
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error', error.message);
            throw new Error(error.message)
        }

    }
}


















export const getProfFee = async (programme_id) => {
    try {
        const response = await api({
            method: 'get',
            url: `/fees/professional/${programme_id}`
        });

        return response.data;
    } catch (error) {
        throw error
    }
}


export const getProfPaymentLink = async (programme_id, data) => {
    try {
        console.log('pay')
        const response = await api({
            method: 'post',
            url: `/fees/professional/${programme_id}/pay`,
            data
        });

        return response.data;
    } catch (error) {
        throw error
    }
}

export const verifyProfPayment = async (programme_id, reference) => {
    try {
        console.log('verifying')
        const response = await api({
            method: 'post',
            url: `/fees/professional/${programme_id}/verify?reference=${reference}`,
        });

        return response.data;
    } catch (error) {
        throw error
    }
}



// ASPIRANTS

export const aspirantLogin = async ({ jamb_reg, password }) => {
    try {
        const response = await api({
            method: 'post',
            url: `/aspirant/signin`,
            data: { jamb_reg, password },
        });

        return response.data;
    } catch (error) {
        throw error
    }
}



export const getPutmePaymentLink = async (token) => {
    try {
        console.log('pay')
        const response = await api({
            method: 'get',
            url: `/fees/putme/pay`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        throw error
    }
}

export const verifyPutmePayment = async (token, reference) => {
    try {
        const response = await api({
            method: 'post',
            url: `/fees/putme/verify?reference=${reference}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        throw error
    }
}
// You can define other API functions here as needed

export const AspirantPayment = async (token, reference) => {
    try {
        const response = await api({
            method: 'post',
            url: `/pay?reference=${reference}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        throw error
    }
}
