import jwt from 'jsonwebtoken';
import { toast } from 'react-toastify';

export const isTokenExpired = (token) => {
    try {
        const decoded = jwt.decode(token);

        const currentTimeInSeconds = Math.floor(Date.now() / 1000);

        if(!decoded) return true

        if (decoded.exp && decoded.exp < currentTimeInSeconds) {
            return true; // Token is expired
        }

        return false; // Token is not expired
    } catch (error) {
        console.error("Error decoding token:", error);
        return true;
    }
}

export const notify = (type, msg) => toast[type](`🦄 ${msg}`, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
});